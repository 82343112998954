import React, { useRef, useEffect, Children, cloneElement } from 'react';
import { useMotionValue } from 'framer-motion';

/*
                                    _
                                   | |
  ___ __ _ _ __ ___  _   _ ___  ___| |
 / __/ _` | '__/ _ \| | | / __|/ _ \ |
| (_| (_| | | | (_) | |_| \__ \  __/ |
 \___\__,_|_|  \___/ \__,_|___/\___|_|

uses:

  Flickity.js
    https://flickity.metafizzy.co/api.html
  Framer Motion
    https://www.framer.com/docs/

flkty PROPERTIES

  `selectedIndex`: Zero-based index of the selected slide.
  `selectedElement`: The selected cell element. For `groupCells`, the first cell element in the selected slide.
  `selectedElements`: An array of elements in the selected slide. Useful for `groupCells`.
  `cells`: The array of cells. Use `cells.length` for the total number of cells.
  `slides`: The array of slides. Useful for `groupCells`. A slide contains multiple cells. If `groupCells` is disabled, then each slide is a cell, so they are one in the same.

flkty EVENTS

  `ready`, (): Triggered after Flickity has been activated.
  `change`, (event, slideIndex): Triggered when the selected slide is changed.
  `select`, (event, slideIndex): Triggered when a slide is selected.
  `settle`, (event, slideIndex): Triggered when the slider is settled at its end position.
  `scroll`, (event, scrollProgress): Triggered when the slider moves. Use scroll to create parallax effects.
  `dragStart`, (event, pointer): Triggered when dragging starts and the slider starts moving.
  `dragMove`, (event, pointer, moveVector): Triggered when dragging moves and the slider moves.
  `dragEnd`, (event, pointer): Triggered when dragging ends.
  `pointerDown`, (event, pointer): Triggered when the user's pointer (mouse, touch, pointer) presses down.
  `pointerMove`, (event, pointer, moveVector): Triggered when the user's pointer moves.
  `pointerUp`, (event, pointer): Triggered when the user's pointer unpresses.
  `staticClick`, (event, pointer, cellElement, cellIndex): Triggered when the user's pointer is pressed and unpressed and has not moved enough to start dragging.
  `lazyLoad`, (event, cellElement): Triggered after an image has been loaded with lazyLoad.
  `bgLazyLoad`, (event, element): Triggered after a background image has been loaded with bgLazyLoad.
  `fullscreenChange (event, isFullscreen)`: Triggered after entering or exiting fullscreen view.

TODO: look at 'compound component' pattern to create a Slide child for this https://www.smashingmagazine.com/2021/08/compound-components-react/

alternatives:

  Glider.js - super-light but minimal
    https://nickpiscitelli.github.io/Glider.js/
    https://github.com/hipstersmoothie/react-glider

  Flicking.js - feature-rich but bloated
    https://naver.github.io/egjs-flicking/docs/
 */

import './carousel.scss';

const flickityDefaults = {
  prevNextButtons: false,
  pageDots: false,
  wrapAround: true,
};

const isBrowser = typeof window !== 'undefined';

// import { useBreakpoints } from 'src/hooks/use-breakpoints';

export function Carousel({ as: El = 'div', children, flickityOptions }) {
  // const { createAt, destroyAt } = useBreakpoints({ createAt: activateAbove, destroyAt: activateBelow });
  const flktyRef = useRef(null);
  const elementRef = useRef(null);
  const slideElements = Children.toArray(children).filter((child) => '$$typeof' in child);
  const { current: slidePositionValues } = useRef(Array(slideElements.length).fill(0).map(useMotionValue));
  const { current: slideProgressValues } = useRef(Array(slideElements.length).fill(0).map(useMotionValue));
  const carouselProgressValue = useMotionValue(0);
  const carouselVelocityValue = useMotionValue(0);
  useEffect(async () => {
    if (!isBrowser) return;
    // using require() here, like `react-flickity-component`, only way to get around SSR
    // https://github.com/yaodingyd/react-flickity-component/blob/f38d7187773b09a3334266f4f2a4c6342f5d7846/src/index.js#L64
    const Flickity = require('flickity');
    var _resize = Flickity.prototype.resize;
    Flickity.prototype._markResized = function () {
      this.element.classList.add('flickity-resized');
    };
    Flickity.createMethods.push('_markResized');
    Flickity.prototype.resize = function () {
      this.element.classList.remove('flickity-resized');
      _resize.call(this);
      this.element.classList.add('flickity-resized');
    };
    if (elementRef.current) {
      flktyRef.current = new Flickity(elementRef.current, {
        ...flickityDefaults,
        ...flickityOptions,
        on: {
          scroll(progress) {
            const { velocity, slides } = this;
            carouselProgressValue.set(progress);
            carouselVelocityValue.set(velocity);
            slides.forEach(function (slide, i) {
              let slideProgress = progress * (slides.length - 1) - i;
              slideProgress =
                slideProgress > slides.length / 2
                  ? slideProgress - slides.length
                  : slideProgress < slides.length / -2
                  ? slideProgress + slides.length
                  : slideProgress;
              slidePositionValues[i].set(slideProgress * slide.outerWidth);
              slideProgressValues[i].set(slideProgress);
            });
          },
          staticClick(event, pointer, cellElement, cellIndex) {
            if (!this.options.prevNextButtons && cellIndex !== this.selectedIndex) {
              this.select(cellIndex, this.options.wrapAround);
            }
          },
        },
      });
      flktyRef.current.reposition(); // trigger initial `scroll` event
    }
    return () => flktyRef.current.destroy();
  }, []);
  return (
    <El ref={elementRef} className="carousel-flickity">
      {slideElements.map((child, i) =>
        cloneElement(child, {
          motionValues: {
            slidePosition: slidePositionValues[i],
            slideProgress: slideProgressValues[i],
            carouselProgress: carouselProgressValue,
            carouselVelocity: carouselVelocityValue,
          },
        }),
      )}
    </El>
  );
}
